import {IconProps} from "@coldpbc/interfaces";

export const ColdSustainabilityIcon = (props: IconProps) => {
  const fillColor= props.color || "white";

  return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M18.4139 11.8339C18.6239 9.37902 18.4061 6.93395 18.2264 5.52403C18.2112 5.40486 18.1202 5.26835 17.926 5.21406L12 3.5575L6.07402 5.21406C5.87978 5.26835 5.7888 5.40486 5.77361 5.52403C5.59389 6.93395 5.37613 9.37902 5.58605 11.8339C5.80189 14.3581 6.44314 16.5454 7.68039 17.7806C9.11627 19.2141 11.0366 20.0586 11.9674 20.4041C11.9806 20.409 11.9913 20.4104 12 20.4104C12.0087 20.4104 12.0194 20.409 12.0326 20.4041C12.9634 20.0586 14.8837 19.2141 16.3196 17.7806C17.5569 16.5454 18.1981 14.3581 18.4139 11.8339ZM12 2L5.67019 3.76944C4.93494 3.97497 4.38218 4.57705 4.28565 5.33436C3.91388 8.2509 3.36138 15.5882 6.6206 18.8421C8.28417 20.5029 10.4444 21.4388 11.4456 21.8103C11.805 21.9437 12.195 21.9437 12.5544 21.8103C13.5556 21.4388 15.7158 20.5029 17.3794 18.8421C20.6386 15.5882 20.0861 8.25091 19.7143 5.33436C19.6178 4.57705 19.0651 3.97497 18.3298 3.76944L12 2Z"
				fill={fillColor}
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M11.25 17.6L11.25 14.6H12.75L12.75 17.6C12.75 18.0142 12.4142 18.35 12 18.35C11.5858 18.35 11.25 18.0142 11.25 17.6Z"
        fill={fillColor}
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M11.25 17.6L11.25 14.6H12.75L12.75 17.6C12.75 18.0142 12.4142 18.35 12 18.35C11.5858 18.35 11.25 18.0142 11.25 17.6Z"
        fill={fillColor}
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M12 15.6C12 15.6 15 13.4509 15 10.8C15 8.14901 12 5.99998 12 5.99998C12 5.99998 9 8.14901 9 10.8C9 13.4509 12 15.6 12 15.6ZM12 13.1727C12.0458 13.1225 12.0917 13.0708 12.1376 13.0177C12.7934 12.2582 13.2 11.4736 13.2 10.8C13.2 10.1264 12.7934 9.34174 12.1376 8.58222C12.0917 8.52912 12.0458 8.47744 12 8.42727C11.9542 8.47744 11.9083 8.52912 11.8624 8.58222C11.2066 9.34174 10.8 10.1264 10.8 10.8C10.8 11.4736 11.2066 12.2582 11.8624 13.0177C11.9083 13.0708 11.9542 13.1225 12 13.1727ZM10.9467 7.45965L10.9472 7.45998L10.9467 7.45965Z"
        fill={fillColor}
			/>
		</svg>
	);
};
